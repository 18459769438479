import React, { useRef } from "react"
import Img, { FixedObject } from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { animated, useSpring, useChain, config } from "react-spring"
import { ReviewCard, ContentAnimator } from "../components/hero"
import SEO from "../components/seo"
import { AppStoreButton } from "../components/button"
import GetStarted from "../components/hero/get-started"
import HeroContainer, {
  HeroContent,
  HeroBackground,
} from "../components/containers/hero"
import { formatCurrency } from "../utils/format"

import ScrollDown from "../images/icon-down.inline.svg"
import CheckIcon from "../images/icons/check.inline.svg"
import HeartIcon from "../images/icons/heart.inline.svg"
import BoltIcon from "../images/icons/bolt.inline.svg"

import andyFace from "../images/faces/andy@2x.png"
import sarahFace from "../images/faces/sarah@2x.png"
import lauraFace from "../images/faces/laura@2x.png"
import vishalFace from "../images/faces/vishal@2x.png"

import * as classes from "../components/hero/hero.module.css"

const sections: Array<React.FunctionComponent<{ screenshot: FixedObject }>> = [
  ({ screenshot }) => (
    <section className={classes.featureContent}>
      <ContentAnimator className={classes.featureContent__inner}>
        <h2>
          Instant
          <BoltIcon role="presentation" />
        </h2>
        <p>
          Complete four simple steps and discover your max buying
          <span className="breaker" />
          power. Invite a co-borrower in a matter seconds.
        </p>
        <AppStoreButton
          isTransparent
          className={classes.featureContent__button}
          text="Try it now"
        />
      </ContentAnimator>
      <ContentAnimator className={classes.featureContent__review}>
        <ReviewCard
          text="The pre-approval process was so simple! I added Andy to my account
          and saw what we can buy together in just a couple of minutes."
          authors="Sarah & Andy"
          faces={[sarahFace, andyFace]}
        />
      </ContentAnimator>
      <ContentAnimator
        className={classes.featureContent__device}
        direction="from-right"
      >
        <div className={classes.featureMobileDeviceFrame}>
          <Img fixed={screenshot} />
        </div>
      </ContentAnimator>
    </section>
  ),
  ({ screenshot }) => (
    <section className={classes.featureContent}>
      <ContentAnimator className={classes.featureContent__inner}>
        <h2>
          Convenient
          <CheckIcon role="presentation" />
        </h2>
        <p>
          Discover all the loan options you qualify for, and generate
          <span className="breaker" />
          verified pre-approvals, anytime, from the palm of your hand.
        </p>
        <AppStoreButton
          isTransparent
          className={classes.featureContent__button}
          text="See options"
        />
      </ContentAnimator>
      <ContentAnimator className={classes.featureContent__review}>
        <ReviewCard
          text="This app does what it says. I answered a couple of questions and got my pre-approval the same day. Huge time saver!"
          authors="Laura"
          faces={[lauraFace]}
        />
      </ContentAnimator>
      <ContentAnimator
        className={classes.featureContent__device}
        direction="from-right"
      >
        <div className={classes.featureMobileDeviceFrame}>
          <Img fixed={screenshot} />
        </div>
      </ContentAnimator>
    </section>
  ),
  ({ screenshot }) => (
    <section className={classes.featureContent}>
      <ContentAnimator className={classes.featureContent__inner}>
        <h2>
          Human
          <HeartIcon role="presentation" />
        </h2>
        <p>
          Chat with your dedicated loan officer to bump up your buying
          <span className="breaker" />
          power, get creative with loan options, or if you just need help.
        </p>
        <AppStoreButton
          isTransparent
          className={classes.featureContent__button}
          text="Get started"
        />
      </ContentAnimator>
      <ContentAnimator className={classes.featureContent__review}>
        <ReviewCard
          text="I had no idea that I could get an appraisal waiver with my mortgage!  My offer stood out to the seller, and I was able to buy the house I wanted against multiple offers."
          authors="Vishal"
          faces={[vishalFace]}
        />
      </ContentAnimator>
      <ContentAnimator
        className={classes.featureContent__device}
        direction="from-right"
      >
        <div className={classes.featureMobileDeviceFrame}>
          <Img fixed={screenshot} />
        </div>
      </ContentAnimator>
    </section>
  ),
]

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      instant: file(relativePath: { eq: "screen_inatant@2x.png" }) {
        childImageSharp {
          fixed(width: 272) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      convinient: file(relativePath: { eq: "screen_convinient@2x.png" }) {
        childImageSharp {
          fixed(width: 272) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      human: file(relativePath: { eq: "screen_human@2x.png" }) {
        childImageSharp {
          fixed(width: 272) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      mockups: file(relativePath: { eq: "mockups@2x.png" }) {
        childImageSharp {
          fixed(width: 496) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  /* const communityValueRef = useRef<any>()
  const { number: communityValue } = useSpring({
    number: 15000000,
    from: { number: 14900000 },
    config: {
      precision: 1,
    },
    ref: communityValueRef,
  }) */

  const heroImageSlideInRef = useRef<any>()
  const heroImageSlideInProps = useSpring({
    from: {
      opacity: 0,
      transform: "translate(100%, var(--cutoff-offset))",
    },
    to: {
      opacity: 1,
      transform: "translate(0, var(--cutoff-offset))",
    },
    config: config.slow,
    ref: heroImageSlideInRef,
  })

  const appearProps = {
    from: {
      transform: "translateY(-10%)",
      opacity: 0,
    },
    to: {
      transform: "translateY(0)",
      opacity: 1,
    },
    config: config.slow,
  }
  const heroTitleAppearRef = useRef<any>()
  const heroTitleAppearProps = useSpring({
    ...appearProps,
    ref: heroTitleAppearRef,
  })
  const scrollGuideAppearRef = useRef<any>()
  const scrollGuideAppearProps = useSpring({
    ...appearProps,
    ref: scrollGuideAppearRef,
  })

  useChain(
    [
      heroTitleAppearRef,
      // communityValueRef,
      heroImageSlideInRef,
      scrollGuideAppearRef,
    ],
    [0, /* 0.5, */ 1, 1.5]
  )

  const featuresRef = useRef<HTMLDivElement>(null)
  const onScrollGuideClick = () => {
    if (!featuresRef.current) {
      return
    }
    window.scrollTo({ top: featuresRef.current.offsetTop, behavior: "smooth" })
  }

  const images = [
    data.instant.childImageSharp.fixed,
    data.convinient.childImageSharp.fixed,
    data.human.childImageSharp.fixed,
  ]

  const backgrounds = [
    HeroBackground.Purple,
    HeroBackground.Blue,
    HeroBackground.Green,
  ]

  return (
    <>
      <SEO title="Home" />
      <div className={classes.snapScrollWrapper}>
        <HeroContainer
          variant="light"
          className={classes.heroCover}
          containerClassName={classes.heroContainer}
        >
          <HeroContent className={classes.heroContent}>
            <animated.section style={heroTitleAppearProps}>
              {/* <h5>
                <animated.span>
                  {communityValue.to(num => formatCurrency(num))}
                </animated.span>{" "}
                community max buying power
              </h5> */}
              <h2>A New Kind of Mortgage Experience</h2>
              <h4>Instant. Convenient. Human.</h4>
              <AppStoreButton text="Download the app" isHero />
            </animated.section>
          </HeroContent>
          <animated.div
            className={classes.scrollGuide}
            style={scrollGuideAppearProps}
          >
            <ScrollDown onClick={onScrollGuideClick} />
          </animated.div>
          <animated.div
            style={heroImageSlideInProps}
            className={classes.sectionCoverImage}
          >
            <Img
              fixed={data.mockups.childImageSharp.fixed}
              loading="eager"
              fadeIn={false}
            />
          </animated.div>
        </HeroContainer>
        <div className={classes.stickySectionWrapper} ref={featuresRef}>
          {sections.map((Section, i) => (
            <HeroContainer
              key={i}
              backgroundGradient={backgrounds[i]}
              variant="dark"
            >
              <section className={classes.featureContainer}>
                <Section screenshot={images[i]} />
              </section>
            </HeroContainer>
          ))}
        </div>
        <GetStarted variant="light" lastContainer />
      </div>
    </>
  )
}

export default IndexPage
