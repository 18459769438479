import React, { useState, useRef, useEffect } from "react"
import cx from "classnames"
import Img, { FixedObject } from "gatsby-image"
import { animated, useTransition, useSpring, config } from "react-spring"
import {
  useWindowScroll,
  useWindowSize,
  useLockBodyScroll,
  useMeasure,
} from "react-use"
import { useWheel } from "react-use-gesture"
import { useInView } from "react-hook-inview"
import { ReactEventHandlers } from "react-use-gesture/dist/types"
import * as classes from "./hero.module.css"

interface ScrollNavigationProps {
  totalPages: number
  pageIndex: number
  onPageChange(newPage: number): unknown
}

interface ReviewCardProps {
  text: string
  authors: string
  faces?: string[]
}

interface PhoneContentAnimatorProps {
  page: number
  images: FixedObject[]
}

interface ContentAnimatorProps {
  className?: string
  direction?: "from-left" | "from-right"
}

export const ReviewCard: React.FunctionComponent<ReviewCardProps> = ({
  text,
  authors,
  faces = [],
}) => (
  <section className={classes.featureReview}>
    <blockquote>
      <p>{text}</p>
      <cite>
        <span className={classes.featureReviewFaces}>
          {faces.map((face, i) => (
            <img src={face} alt="Authors Face" role="presentation" key={i} />
          ))}
        </span>
        {authors}
      </cite>
    </blockquote>
  </section>
)

export const PhoneContentAnimator: React.FunctionComponent<
  PhoneContentAnimatorProps
> = ({ images, page }) => {
  const transitions = useTransition(page, p => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        return (
          <animated.div
            key={key}
            style={props}
            className={classes.featureDeviceContent}
          >
            <Img fixed={images[item]} loading="eager" fadeIn={false} />
          </animated.div>
        )
      })}
    </>
  )
}

export const ContentAnimator: React.FunctionComponent<ContentAnimatorProps> = ({
  children,
  className,
  direction = "from-left",
}) => {
  const [ref, inView] = useInView({
    rootMargin: "-130px 0px",
    unobserveOnEnter: true,
  })

  const transformStartValue =
    direction === "from-left" ? "translateX(-10%)" : "translateX(10%)"

  const props = useSpring({
    from: {
      transform: transformStartValue,
      opacity: 0,
    },
    to: {
      transform: inView ? "translateX(0)" : transformStartValue,
      opacity: inView ? 1 : 0,
    },
    config: config.slow,
  })

  return (
    <animated.div ref={ref} style={props} className={className}>
      {children}
    </animated.div>
  )
}
